import React, { useState } from "react";
import { ApErrorToast, ApSuccessToast, ApiService } from "../services";
import { IApiBinding, IBinanceApi, IBitgetApi, IOkxApi, ITriiixApi } from "../models/profile.interface";
import { ICreateFXTradingAccount, IFXTradingAccount, IFXTradingProvider, IProviderSubscriptionDetails } from "../models";
import { useNavigation } from "@react-navigation/native";

interface IApiBindingState {
  loading: boolean;
  initLoading: boolean;
  fXTradingAccounts: IFXTradingAccount[]
  fXTradingProviders: IFXTradingProvider[]
  selectedProvider: IFXTradingProvider
  providerSubscriptionDetail: IProviderSubscriptionDetails
  unbindApi: (type: "bn" | "okx" | "triiix") => Promise<void>;
  bindApi: (payload: IApiBinding) => Promise<void>;
  getFXTradingAccounts: () => Promise<any>;
  getFXTradingProviders: () => Promise<any>;
  getProviderSubscriptionDetails: (id: string) => Promise<any>;
  createFXTradingAccount: (payload: ICreateFXTradingAccount) => Promise<any>;
  deleteFXTradingAccount: (id: string) => Promise<any>;
  updateFXTradingAccount: (payload: {id: string, label: string}) => Promise<any>;
  setSelectedProvider: React.Dispatch<React.SetStateAction<IFXTradingProvider>>
  updateProviderSubscriptionDetails: (payload: any) => Promise<any>;
}

const ApiBindingContext = React.createContext<IApiBindingState>({
  loading: false,
  initLoading: true,
  fXTradingAccounts: [],
  fXTradingProviders: [],
  selectedProvider: null,
  providerSubscriptionDetail: null,
  setSelectedProvider(){},
  unbindApi(type: "bn" | "okx" | "triiix") {},
  bindApi(payload: IApiBinding) {},
  getFXTradingAccounts() {},
  getFXTradingProviders() {},
  createFXTradingAccount(payload) {},
  deleteFXTradingAccount() {},
  updateFXTradingAccount(payload) {},
  getProviderSubscriptionDetails(id) {},
  updateProviderSubscriptionDetails(payload) {}
} as unknown as IApiBindingState);

const useApiBindingState = () => {
  const context = React.useContext(ApiBindingContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

const ApiBindingContextProvider: React.FC<IProps> = ({ children }) => {
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [fXTradingAccounts, setFXTradingAccounts] = useState<IFXTradingAccount[]>([])
  const [fXTradingProviders, setFXTradingProviders] = useState<IFXTradingProvider[]>([])
  const [selectedProvider, setSelectedProvider] = useState<IFXTradingProvider>()
  const [providerSubscriptionDetail, setProviderSubscriptionDetail] = useState<IProviderSubscriptionDetails>()
  const navigation: any = useNavigation();

  const getFXTradingAccounts = async () => {
    setLoading(true);
    return ApiService.get(`/metatrader/getAccounts`)
      .then((rs) => {
        setFXTradingAccounts(rs?.data?.data)
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFXTradingProviders = async () => {
    setLoading(true);
    return ApiService.get(`/metatrader/getProviders`)
      .then((rs) => {
        setFXTradingProviders(rs?.data?.data)
        return rs?.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProviderSubscriptionDetails = async (id: string) => {
    setInitLoading(true);
    return ApiService.get(`/metatrader/getProviderSubscriptionDetails?provider_id=${id}`)
      .then((rs) => {
        setProviderSubscriptionDetail(rs?.data?.data)
        return rs?.data?.data;
      })
      .finally(() => {
        setInitLoading(false);
      });
  };

  const updateProviderSubscriptionDetails = async (payload: any) => {
    setLoading(true);
    return ApiService.post("/metatrader/updateProviderSubscriptionDetails", payload)
    .then((rs) => {
      if(rs?.data){
        ApSuccessToast("", rs.data?.msg)
        getFXTradingProviders()
        navigation.navigate("FXTradingCopyTrade")
        return rs.data?.msg
      }
    })
    .catch((err) => {
      ApErrorToast("Error", err);
      setLoading(false)
      throw err;
    })
  };

  const createFXTradingAccount = async (payload: ICreateFXTradingAccount) => {
    setLoading(true);
    return ApiService.post("/metatrader/addAccount", payload)
    .then((rs) => {
      if(rs?.data){
        ApSuccessToast("", rs.data?.msg)
        getFXTradingAccounts()
        navigation.navigate("FXTradingAccounts")
        return rs.data?.msg
      }
    })
    .catch((err) => {
      ApErrorToast("Error", err);
      setLoading(false)
      throw err;
    })
  };

  const updateFXTradingAccount = async (payload: {id: string, label: string}) => {
    setLoading(true);
    return ApiService.post("/metatrader/updateAccount", payload)
    .then((rs) => {
      if(rs?.data){
        ApSuccessToast("", rs.data?.msg)
        getFXTradingAccounts()
        navigation.navigate("FXTradingAccounts")
        return rs.data?.msg
      }
    })
    .catch((err) => {
      ApErrorToast("Error", err);
      setLoading(false)
      throw err;
    })
  };

  const deleteFXTradingAccount = async (id: string) => {
    setLoading(true);
    return ApiService.post("/metatrader/deleteAccount", {id})
    .then((rs) => {
      if(rs?.data){
        ApSuccessToast("", rs.data?.msg)
        getFXTradingAccounts()
        return rs.data?.msg
      }
    })
    .catch((err) => {
      ApErrorToast("Error", err);
      setLoading(false)
      throw err;
    })
  };

  const bindApi = async (payload: any): Promise<void> => {
    setLoading(true);

    let url = `profile/updateMemberBinanceInfo`;
    payload = {
      ...payload,
      bn_api_key: payload.api_key,
      bn_api_secret: payload.api_secret,
    } as IBinanceApi;
    if (payload.type == "okx") {
      url = `profile/updateMemberOkxInfo`;
      payload = {
        okx_password: payload.passphrase,
        okx_api_key: payload.api_key,
        okx_api_secret: payload.api_secret,
      } as IOkxApi;
    } else if(payload.type == "bitget") {
      url = `profile/updateMemberBitgetInfo`
      payload = {
        bitget_api_key: payload.api_key,
        bitget_api_secret: payload.api_secret,
        bitget_passphrase: payload.passphrase,
      } as IBitgetApi;
    } else if(payload.type == "triiix") {
      url = `profile/updateMemberTriiixInfo`
      payload = {
        triiix_api_key: payload.api_key,
        triiix_api_secret: payload.api_secret
      } as ITriiixApi;
    }

    delete payload.api_key;
    delete payload.api_secret;
    delete payload.type;

    return ApiService.post(url, payload)
      .then((rs) => {
        ApSuccessToast("Api Binding", rs?.data?.msg);
      })
      .catch((err) => {
        ApErrorToast("Api Binding", err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const unbindApi = async (type: "bn" | "okx" | "triiix" | "bitget"): Promise<void> => {
    return ApiService.post(
      `profile/deleteMemberExchInfo
    `,
      { exch: type }
    )
      .then((rs) => {
        ApSuccessToast("Unbind Api", rs?.data?.msg);
      })
      .catch((err) => {
        ApErrorToast("Unbind Api", err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ApiBindingContext.Provider
      value={{
        updateProviderSubscriptionDetails,
        initLoading,
        providerSubscriptionDetail,
        getProviderSubscriptionDetails,
        selectedProvider,
        setSelectedProvider,
        fXTradingProviders,
        getFXTradingProviders,
        updateFXTradingAccount,
        fXTradingAccounts,
        getFXTradingAccounts,
        loading,
        bindApi,
        unbindApi,
        createFXTradingAccount,
        deleteFXTradingAccount
      }}
    >
      {children}
    </ApiBindingContext.Provider>
  );
};

export { ApiBindingContextProvider, useApiBindingState };