import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageBackground,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import DropDownPicker from "react-native-dropdown-picker";
import { RefreshControl } from "react-native-web-refresh-control";
import { useDispatch, useSelector } from "react-redux";
import { components } from "../components";
import { ApEmptyState, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { usePnlState, useProfileState } from "../context";
import { setHideProfit } from "../store/hideProfitSlice";
import { svg } from "../svg";
import ArrowSevenSvg from "../svg/ArrowSevenSvg";
import ArrowSixSvg from "../svg/ArrowSixSvg";
import { toCurrency } from "../utils";

const MyAssets: React.FC = ({ navigation }: any) => {
  const { getPnl, loading, pnlResponse } = usePnlState();
  const { dashboardData } = useProfileState();
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const [selected, setSelected] = useState("DailyProfit");
  const [open, setOpen] = useState(false);
  const [market, setMarket] = useState<"1" | "2" | "3">("1");
  const [items, setItems] = useState([
    { label: t("Spot"), value: "1" },
    { label: t("Future"), value: "2" },
    // { label: t("Forex"), value: "3" },
  ]);
  const dispatch = useDispatch();
  const hideProfit = useSelector(
    (state: any) => state.hideProfitSlice.hideProfit
  );

  useEffect(() => {
    getPnl(market);
  }, [market]);

  const handleRefresh = () => {
    setRefreshing(true);
    getPnl(market).finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return (
      <components.CustomHeader
        title="Profit"
        titleTranslateKey="Profit"
        goBack={true}
      />
    );
  };

  const RenderStrategyProfit = () => (
    <View style={{ borderRadius: 10 }}>
      {!!pnlResponse?.total_pnl_symbol?.length ? (
        pnlResponse?.total_pnl_symbol?.map((e: any) => (
          <TouchableOpacity
            onPress={() => navigation.navigate("StrategyProfit")}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingVertical: 10,
                borderBottomWidth: 1,
                borderColor: "#e4e4e499",
              }}
            >
              <View style={{ width: "45%" }}>
                <ApText
                  translateKey="BotsStrategy"
                  style={{ fontWeight: "500" }}
                >
                  Bots Strategy
                </ApText>
              </View>

              <Text
                style={{
                  width: "55%",
                  fontWeight: "bold",
                  color: "#F37021",
                  alignSelf: "center",
                  fontSize: 15,
                  textAlign: "right",
                }}
              >
                {e?.amount ? toCurrency(+e?.amount) : "0.00"}
              </Text>
              <View
                style={{
                  width: "10%",
                  marginLeft: "auto",
                  alignSelf: "center",
                  alignItems: "flex-end",
                }}
              >
                <Image
                  source={require("../../assets/other-icons/14.png")}
                  style={{ width: 6, height: 9 }}
                />
              </View>
            </View>
          </TouchableOpacity>
        ))
      ) : (
        <ApEmptyState />
      )}
    </View>
  );

  const RenderTokenProfit = () => (
    <View style={{ borderRadius: 10 }}>
      {pnlResponse?.total_pnl_symbol?.length ? (
        pnlResponse?.total_pnl_symbol?.map((e: any, index) => (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("ProfitDetails", {
                market,
                symbol: e.symbol,
              })
            }
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingVertical: 10,
                borderBottomWidth:
                  index + 1 === pnlResponse?.total_pnl_symbol.length ? 0 : 1,
                borderColor: "#e4e4e499",
              }}
            >
              <View style={{ width: "45%" }}>
                <ApText style={{ fontWeight: "500" }}>
                  {e.symbol.split("USDT")[0].toUpperCase() + (market == '3' ?'':"/USDT")}
                </ApText>
              </View>

              <ApText
                style={{
                  width: "55%",
                  fontWeight: "bold",
                  color: "#F37021",
                  alignSelf: "center",
                  fontSize: 15,
                  textAlign: "right",
                }}
              >
                {e?.amount ? toCurrency(+e?.amount) : "0.00"}
              </ApText>
              <View
                style={{
                  width: "10%",
                  marginLeft: "auto",
                  alignSelf: "center",
                  alignItems: "flex-end",
                }}
              >
                <Image
                  source={require("../../assets/other-icons/14.png")}
                  style={{ width: 6, height: 9 }}
                />
              </View>
            </View>
          </TouchableOpacity>
        ))
      ) : (
        <ApEmptyState />
      )}
    </View>
  );

  const RenderDailyProfit = () => (
    <View style={{ borderRadius: 10 }}>
      {!!pnlResponse?.total_pnl_date?.length ? (
        pnlResponse?.total_pnl_date?.map((e: any, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => navigation.navigate("ProfitDetails", { 
              market, 
              from_date: e.date,
              to_date: e?.date 
            })}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingVertical: 10,
                borderBottomWidth:
                  index + 1 === pnlResponse?.total_pnl_date.length ? 0 : 1,
                borderColor: "#e4e4e499",
              }}
            >
              <View style={{ width: "40%" }}>
                <ApText style={{ fontWeight: "500" }}>{e?.date}</ApText>
              </View>

              <ApText
                style={{
                  width: "50%",
                  fontWeight: "bold",
                  color: "#F37021",
                  alignSelf: "center",
                  fontSize: 15,
                  textAlign: "right",
                }}
              >
                {e?.amount ? toCurrency(+e?.amount) : "0.00"}
              </ApText>
              <View
                style={{
                  width: "10%",
                  marginLeft: "auto",
                  alignSelf: "center",
                  alignItems: "flex-end",
                }}
              >
                <Image
                  source={require("../../assets/other-icons/14.png")}
                  style={{ width: 6, height: 9 }}
                />
              </View>
            </View>
          </TouchableOpacity>
        ))
      ) : (
        <ApEmptyState />
      )}
    </View>
  );

  const RenderMonthlyPnl = () => (
    <View style={{ borderRadius: 10 }}>
      {!!pnlResponse?.total_pnl_month?.length ? (
        pnlResponse?.total_pnl_month?.map((e, index) => (
          <View key={index}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingVertical: 10,
                borderBottomWidth:
                  index + 1 === pnlResponse?.total_pnl_date.length ? 0 : 1,
                borderColor: "#e4e4e499",
              }}
            >
              <View style={{ width: "45%" }}>
                <ApText style={{ fontWeight: "500" }}>
                  {e?.year}-{e?.month}
                </ApText>
              </View>

              <ApText
                style={{
                  width: "55%",
                  fontWeight: "bold",
                  color: "#F37021",
                  alignSelf: "center",
                  fontSize: 15,
                  textAlign: "right",
                }}
              >
                {e?.amount ? toCurrency(+e?.amount) : "0.00"}
              </ApText>
            </View>
          </View>
        ))
      ) : (
        <ApEmptyState />
      )}
    </View>
  );

  const renderContent = () => {
    return (
      <ScrollView
        style={{ backgroundColor: theme.COLORS.bgColor }}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View>
          <ImageBackground
            style={{ width: "100%", backgroundColor: "#F37021"}}
            source={require("../../assets/bg-profit.png")}
            imageStyle={{
              width: "100%",
              backgroundColor: "#F37021",
              resizeMode: "contain",
              alignSelf: "flex-end",
              position: "absolute",
            }}
          >
            <View style={{ padding: 15, paddingBottom: 10 }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <ApText
                  translateKey="TotalProfit"
                  style={{ color: "#ebebeb", fontSize: 14, marginRight: 10 }}
                />

                <TouchableOpacity
                  onPress={() => {
                    dispatch(setHideProfit(true));
                  }}
                >
                  {hideProfit ? <svg.EyeOffWhiteSvg /> : <svg.EyeOnWhiteSvg />}
                </TouchableOpacity>
              </View>

              <ApText
                style={{ fontWeight: "bold", fontSize: 18, color: "white" }}
              >
                {
                  hideProfit
                    ? "*****"
                    : // : pnlResponse?.ytd_self_pnl_amt
                      toCurrency(+dashboardData?.self_pnl_amt, "") + " USDT"
                  // : "0.00"
                }
              </ApText>
            </View>

            <View style={{ padding: 15, paddingTop: 0 }}>
              <View
                style={{
                  paddingBottom: 30,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: 10,
                  paddingRight: 5,
                }}
              >
                <View style={{}}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <ApText
                      translateKey="YesterdayProfit"
                      style={{
                        color: "#ebebeb",
                        fontSize: 14,
                        marginRight: 10,
                      }}
                    />
                    {/* 
                    <TouchableOpacity
                      onPress={() => {
                        dispatch(setHideProfit(true));
                      }}
                    >
                      {hideProfit ? (
                        <svg.EyeOffWhiteSvg />
                      ) : (
                        <svg.EyeOnWhiteSvg />
                      )}
                    </TouchableOpacity> */}
                  </View>

                  <ApText
                    style={{ fontWeight: "bold", fontSize: 18, color: "white" }}
                  >
                    {
                      hideProfit
                        ? "*****"
                        : // : pnlResponse?.ytd_self_pnl_amt
                          toCurrency(+pnlResponse?.ytd_self_pnl_amt, "") +
                          " USDT"
                      // : "0.00"
                    }
                  </ApText>
                </View>

                <View style={{}}>
                  <ApText
                    translateKey="Last30dayProfit"
                    style={{ color: "#ebebeb", fontSize: 14 }}
                  >
                    Last 30-day Profit
                  </ApText>
                  <ApText
                    style={{ fontWeight: "bold", fontSize: 18, color: "white" }}
                  >
                    {
                      hideProfit
                        ? "*****"
                        : // : pnlResponse?.month_self_pnl_amt
                          toCurrency(+pnlResponse?.month_self_pnl_amt, "") +
                          " USDT"
                      // : "0.00"
                    }
                  </ApText>
                </View>
              </View>
            </View>
          </ImageBackground>

          <View style={{ position: "absolute", width: "100%", top: "75%" }}>
            <View
              style={{
                flex: 1,
                backgroundColor: "#FCF8F6",
                margin: 15,
                borderRadius: 10,
                paddingVertical: 20,
                paddingHorizontal: 17,
              }}
            >
              <View>
                <ScrollView>
                  <View style={{ marginBottom: 10, zIndex: 999 }}>
                    <DropDownPicker
                      open={open}
                      value={market}
                      items={items}
                      setOpen={setOpen}
                      setValue={setMarket}
                      setItems={setItems}
                      ArrowDownIconComponent={({ style }) => <ArrowSixSvg />}
                      ArrowUpIconComponent={({ style }) => <ArrowSevenSvg />}
                      dropDownContainerStyle={{ borderWidth: 0 }}
                      textStyle={{
                        fontSize: 18,
                        color: "#F37021",
                        fontWeight: "500",
                      }}
                      // multiple={true}
                      style={{
                        backgroundColor: "none",
                        borderWidth: 0,
                        paddingHorizontal: 0,
                        width: 70,
                      }}
                      mode="SIMPLE"
                      badgeDotColors={[
                        "#e76f51",
                        "#00b4d8",
                        "#e9c46a",
                        "#e76f51",
                        "#8ac926",
                        "#00b4d8",
                        "#e9c46a",
                      ]}
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 10,
                    }}
                  >
                    <View>
                      <ApText
                        translateKey="YesterdayProfit"
                        style={{ color: "#878787", fontSize: 14 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "bold",
                          color: "#F37021",
                          fontSize: 16,
                          paddingTop: 1,
                        }}
                      >
                        {
                          hideProfit
                            ? "*****"
                            : // : pnlResponse?.ytd_self_pnl_amt
                              toCurrency(+pnlResponse?.ytd_pnl, "") + " USDT"
                          // : "0.00"
                        }
                      </ApText>
                    </View>
                    <View>
                      <ApText
                        translateKey="Last30dayProfit"
                        style={{ color: "#878787", fontSize: 14 }}
                      />
                      <ApText
                        style={{
                          fontWeight: "bold",
                          color: "#F37021",
                          fontSize: 16,
                          paddingTop: 1,
                        }}
                      >
                        {
                          hideProfit
                            ? "*****"
                            : // : pnlResponse?.ytd_self_pnl_amt
                              toCurrency(+pnlResponse?.month_pnl, "") + " USDT"
                          // : "0.00"
                        }
                      </ApText>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      borderRadius: 50,
                      marginVertical: 10,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        width: "auto",
                        marginRight: 15,
                      }}
                      onPress={() => setSelected("DailyProfit")}
                    >
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 50,
                          paddingVertical: 3,
                        }}
                        // start={{ x: 0, y: 0 }}
                        // end={{ x: 1, y: 0 }}
                      >
                        <ApText
                          translateKey="DailyProfit"
                          style={{
                            ...theme.FONTS.Mulish_400Regular,
                            // ...styles.selected,
                            fontSize: 14,
                            color:
                              selected === "DailyProfit"
                                ? "#F37021"
                                : "#F37021",
                            lineHeight: 14 * 1.6,
                            fontWeight:
                              selected === "DailyProfit" ? "bold" : "normal",
                            borderBottomWidth:
                              selected === "DailyProfit" ? 2 : 2,
                            borderColor:
                              selected === "DailyProfit"
                                ? "#F37021"
                                : "#FCF8F6",
                          }}
                        />
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        width: "auto",
                        marginRight: 15,
                      }}
                      onPress={() => setSelected("TokenProfit")}
                    >
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 50,
                          paddingVertical: 3,
                        }}
                      >
                        <ApText
                          translateKey="TokenProfit"
                          style={{
                            fontSize: 14,
                            color:
                              selected === "TokenProfit"
                                ? "#F37021"
                                : "#F37021",
                            lineHeight: 14 * 1.6,
                            fontWeight:
                              selected === "TokenProfit" ? "bold" : "normal",
                            borderBottomWidth:
                              selected === "TokenProfit" ? 2 : 2,
                            borderColor:
                              selected === "TokenProfit"
                                ? "#F37021"
                                : "#FCF8F6",
                          }}
                        />
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        width: "auto",
                      }}
                      onPress={() => setSelected("MonthlyPnl")}
                    >
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 50,
                          paddingVertical: 3,
                        }}
                      >
                        <ApText
                          translateKey="MonthlyPnl"
                          style={{
                            fontSize: 14,
                            color:
                              selected === "MonthlyPnl" ? "#F37021" : "#F37021",
                            lineHeight: 14 * 1.6,
                            fontWeight:
                              selected === "MonthlyPnl" ? "bold" : "normal",
                            borderBottomWidth:
                              selected === "MonthlyPnl" ? 2 : 2,
                            borderColor:
                              selected === "MonthlyPnl" ? "#F37021" : "#FCF8F6",
                          }}
                        />
                      </View>
                    </TouchableOpacity>
                    {/* <TouchableOpacity
                      style={{
                        width: "auto",
                      }}
                      onPress={() => setSelected("StrategyProfit")}
                    >
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 50,
                          paddingVertical: 3,
                        }}
                      >
                        <ApText
                          translateKey="StrategyProfit"
                          style={{
                            color:
                              selected === "StrategyProfit"
                                ? "#F37021"
                                : "#F37021",
                            lineHeight: 14 * 1.6,
                            fontWeight:
                              selected === "StrategyProfit" ? "bold" : "normal",
                            borderBottomWidth:
                              selected === "StrategyProfit" ? 2 : 2,
                            borderColor:
                              selected === "StrategyProfit"
                                ? "#F37021"
                                : "#FCF8F6",
                          }}
                        >
                          Strategy Profit
                        </ApText>
                      </View>
                    </TouchableOpacity> */}
                  </View>
                </ScrollView>
              </View>
              <View>
                {selected == "DailyProfit" ? (
                  <RenderDailyProfit />
                ) : selected == "TokenProfit" ? (
                  <RenderTokenProfit />
                ) : selected == "MonthlyPnl" ? (
                  <RenderMonthlyPnl />
                ) : (
                  <RenderStrategyProfit />
                )}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};

export default MyAssets;
