import React, { useEffect } from "react";
import {
  Image,
  Linking,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import { ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState } from "../context";
import { svg } from "../svg";

const Payments: React.FC = ({ navigation }: any) => {
  const { getExchangeRateInfo, exchangeRateInfo, loading } = useProfileState();
  useEffect(() => {
    getExchangeRateInfo();
  }, []);

  const platform = [
    // {
    //   id: "1",
    //   title: "FXTRADING",
    //   icon: require("../../assets/icons/fxtrading.png"),
    //   link: "fxt",
    // },
    {
      id: "2",
      title: "Binance",
      icon: require("../../assets/icons/09.png"),
      link: "bn",
    },
    {
      id: "3",
      title: "OKXNonCN",
      icon: require("../../assets/icons/okx.png"),
      link: "okx",
    //   description: "OKXDescription",
    },
    {
      id: "4",
      title: "OKXCN",
      icon: require("../../assets/icons/okx.png"),
      link: "okx_cn",
    //   description: "OKXDescription",
    },
    {
      id: "5",
      title: "TriiiX",
      icon: require("../../assets/icons/triiix.png"),
      link: "triiix",
    //   description: "TriiixDescription",
    },
    {
      id: "6",
      title: "Bitget",
      icon: require("../../assets/icons/bitget.png"),
      link: "bitget",
    //   description: "TriiixDescription",
    },
  ];

  const renderHeader = () => {
    return (
      <components.Header
        title="Account"
        titleTranlateKey="Account"
        goBack={true}
      />
    );
  };

  const handleRefresh = () => {
    getExchangeRateInfo();
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
        }}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View
          style={{
            backgroundColor: "#FFD9CF",
            padding: 12,
            borderRadius: 10,
            marginTop: 15,
            marginBottom: 20,  
            flexDirection: "row",
          }}
        >
          <ApText
            style={{ color: "#F37021", width: "80%", fontSize: 13 }}
            translateKey="OpenAccountPageNote"
          />
          <Image
            source={require("../../assets/icons/account-hint.png")}
            style={{ width: 86, height: 57 }}
          />
        </View>

        <>
          {exchangeRateInfo && Object.keys(exchangeRateInfo).length === 0 ? (
            <View
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <svg.AccountSvg style={{ width: "50%", height: "50%" }} />
              <ApText
                style={{
                  marginVertical: "4px",
                  textAlign: "center",
                }}
                translateKey="NoBoundAccountMsg"
              />
            </View>
          ) : (
            <View>
              {platform.map((item, key) => {
                return (
                  <View
                    key={key}
                    style={{
                      width: "100%",
                      backgroundColor: "#fff5f0ba",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: 'space-between',
                      padding: 10,
                      paddingVertical: 20,
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        width: "65%",
                      }}
                    >
                      <Image
                        source={item?.icon}
                        style={{
                          borderRadius: 50,
                          width: 40,
                          height: 40,
                          marginRight: 14,
                        }}
                      />
                      <View style={{ flex: 1 }}>
                        <ApText
                          translateKey={item?.title}
                          size="sm"
                          style={{
                            color: theme.COLORS.mainDark,
                            fontWeight: "500",
                          }}
                        >
                          {item?.title}
                        </ApText>
                        {/* <ApText
                          translateKey={item?.description}
                          size="xs"
                          style={{
                            color: theme.COLORS.mainDark,
                            fontWeight: "500",
                          }}
                        >
                          {item?.description}
                        </ApText> */}
                      </View>
                    </View>
                    <View
                      style={{
                        width: "30%",
                      }}
                    >
                      {exchangeRateInfo && exchangeRateInfo[item.link] ? (
                        <TouchableOpacity
                          onPress={() => {
                            Linking.openURL(exchangeRateInfo[item.link]);
                          }}
                          style={{
                            borderRadius: 6,
                            paddingVertical: 3,
                            paddingHorizontal: 2,
                            backgroundColor: "#F37021",
                          }}
                        >
                          <ApText
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: 13,
                            }}
                            translateKey="OpenAccount"
                          />
                        </TouchableOpacity>
                      ) : (
                        <TouchableOpacity
                          disabled
                          style={{
                            borderRadius: 6,
                            paddingVertical: 3,
                            paddingHorizontal: 2,
                            backgroundColor: "#ddd",
                          }}
                        >
                          <ApText
                            style={{
                              color: "#333",
                              textAlign: "center",
                              fontSize: 13,
                            }}
                            translateKey="Unavailable"
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                );
              })}
            </View>
          )}
        </>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
    </ApSafeAreaView>
  );
};

export default Payments;
