import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import Dialog, {
  DialogContent,
  SlideAnimation,
} from "react-native-popup-dialog";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import {
  ApButton,
  ApEmptyState,
  ApLoader,
  ApMarketType,
  ApPlatformTabs,
  PageHint,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import {
  useProfileState,
  useStrategyState,
  useTradingContext,
} from "../context";
import { ApiService } from "../services";
import { svg } from "../svg";
import Balance from "./Trade/TradingBots/components/balance";
import { IBotPlan } from "../models";

const Trade: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const [showBindModal, setShowBindModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [strategyID, setStrategyID] = useState<string>();
  const navigation: any = useNavigation();
  const { getProfileData } = useProfileState();

  const {
    setMaxSets,
    generateOrderSettings,
    setStatus,
    setActive_EMA4,
    setActive_MACD,
    strategies,
    setShowSpotTab,
    setStrategies,
    market,
    platform,
    setTrendMarginControl,
    setEvalOnTrendValues,
    setMarket,
    setPlatform,
    setNumOfReentry,
    setExtraSettings,
    deleteBotPlan,
    setSelectedStrategy,
    loading,
    getBotPlans,
    botPlans,
    getAssetInfo,
    assetInfo,
    futureUtilisedFund,
    spotUtilisedFund,
    assetInfoStatus,
    getBotExecutors
  } = useTradingContext();

  useEffect(() => {
    getAssetInfo();
    getBotExecutors(market, true);
  }, [platform]);

  const { setSymbols } = useStrategyState();

  const renderHeader = () => {
    return (
      <components.Header
        title="Start Trading"
        titleTranlateKey="StartTrading"
      />
    );
  };

  useEffect(() => {
    ApiService.get(`/profile/me`).then((rs) => {
      if (
        rs?.data?.data.bn_api_key === "" &&
        rs?.data?.data.okx_api_key === "" &&
        rs?.data?.data.triiix_api_key === ""
      ) {
        setShowBindModal(true);
      }
    });
    getBotPlans();
    getProfileData();
  }, []);

  const handleDelete = () => {
    deleteBotPlan({ bp_id: strategyID });
    setDeleteModal(false);
  };

  const handleRefresh = () => {
    setRefreshing(true);
    getBotPlans().finally(() => {
      setRefreshing(false);
    });
  };

  const handleNavigate = (item: IBotPlan) => {
    // setActive_MACD(null)
    // setActive_EMA4(null)
    setSelectedStrategy(item);
    setMaxSets(parseInt(item.max_sets))
    setNumOfReentry(parseInt(item.max_entries));
    setEvalOnTrendValues(null);
    setExtraSettings(item.max_entries, true, item).then((rs) => {
      navigation.navigate("EditStrategy");
    });
    if(item.plan_type == "1"){
      generateOrderSettings(item.max_sets, item, true)
    }
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          // paddingBottom: 10,
          flexGrow: 1,
          justifyContent: "space-between",
        }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={handleRefresh} />
        }
      >
        <View style={{ paddingHorizontal: 15 }}>
          <View style={{ paddingVertical: 10 }}>
            <ApPlatformTabs selected={platform} onSelect={setPlatform} />
          </View>

          <PageHint translateKey="StartTradingPageNote" />

          <ApMarketType selected={market} onSelect={setMarket} />

          <Balance
            assetInfoStatus={assetInfoStatus}
            market={market}
            assetInfo={assetInfo}
            futureUtilisedFund={futureUtilisedFund}
            spotUtilisedFund={spotUtilisedFund}
          />

          <View>
            <>
              {market === "2" ? (
                <>
                  <View style={{ paddingVertical: 0 }}>
                    <ApText
                      style={{
                        fontWeight: "500",
                        paddingTop: 6,
                      }}
                    >
                      <ApText
                        style={{ color: "#F37021", fontSize: 15 }}
                        translateKey="CreateNewFutureStrategies"
                      />
                    </ApText>
                    {botPlans.default.filter((bp) => bp?.market === "2")
                      .length > 0 ? (
                      botPlans.default
                        .filter((bp) => bp?.market === "2")
                        .map((item, index) => (
                          <TouchableOpacity
                            key={index}
                            onPress={() => {
                              handleNavigate(item);
                            }}
                          >
                            <View
                              style={{
                                width: "100%",
                                backgroundColor: "#fff5f0ba",
                                marginTop: 10,
                                borderRadius: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                paddingHorizontal: 10,
                                paddingVertical: 15,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "85%",
                                }}
                              >
                                <View style={{ width: 75 }}>
                                  <Image
                                    source={require("../../assets/users/stable-str.png")}
                                    style={{
                                      width: 75,
                                      height: 63,
                                    }}
                                  />
                                </View>
                                <ApText
                                  style={{
                                    color: theme.COLORS.mainDark,
                                    fontWeight: "500",
                                    paddingLeft: 15,
                                  }}
                                >
                                  {item?.label_code}
                                </ApText>
                                {item.plan_type == "1" && (
                                  <View style={styles.botPlanTypeOneLabel}>
                                    <ApText size="xs" color="#fff" translateKey="BotPlanTypeOneLabel"/>
                                  </View>
                                )}
                              </View>
                            </View>
                          </TouchableOpacity>
                        ))
                    ) : (
                      <ApEmptyState
                        heading="Strategy"
                        message="No strategy found"
                      />
                    )}
                  </View>

                  <View style={{ paddingVertical: 10 }}>
                    <ApText
                      style={{
                        fontWeight: "500",
                        paddingTop: 6,
                      }}
                    >
                      <ApText
                        style={{ color: "#F37021", fontSize: 15 }}
                        translateKey="MyFutureStrategies"
                      />
                    </ApText>
                    {botPlans.my.filter((bp) => bp?.market === "2").length >
                    0 ? (
                      botPlans.my
                        .filter((bp) => bp?.market === "2")
                        .map((item, index) => (
                          <TouchableOpacity
                            key={index}
                            onPress={() => {
                              handleNavigate(item);
                            }}
                          >
                            <View
                              style={{
                                width: "100%",
                                backgroundColor: "#fff5f0ba",
                                marginTop: 10,
                                borderRadius: 10,
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                paddingHorizontal: 10,
                                paddingVertical: 15,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    width: "10%",
                                    marginHorizontal: 15,
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    source={require("../../assets/users/future-str.png")}
                                    style={{
                                      width: 63,
                                      height: 63,
                                    }}
                                  />
                                </View>

                                <View 
                                  style={{
                                    paddingHorizontal: 15, 
                                    width: "75%", 
                                    flexDirection: "row", 
                                    alignItems: "center"
                                  }}
                                >
                                  <ApText
                                    style={{
                                      fontSize: 15,
                                      color: theme.COLORS.mainDark,
                                      fontWeight: "500",                                    
                                    }}
                                  >
                                    {item?.label_code}
                                  </ApText>

                                  {item.plan_type == "1" && (
                                    <View style={styles.botPlanTypeOneLabel}>
                                      <ApText size="xs" color="#fff" translateKey="BotPlanTypeOneLabel"/>
                                    </View>
                                  )}
                                </View>

                                <TouchableOpacity
                                  style={{
                                    alignItems: "center",
                                  }}
                                  onPress={() => {
                                    setStrategyID(item?.id);
                                    setDeleteModal(true);
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <svg.RycleBinSvg />
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </TouchableOpacity>
                        ))
                    ) : (
                      <ApEmptyState
                        heading="Strategy"
                        message="No strategy found"
                      />
                    )}
                  </View>
                </>
              ) : (
                <>
                  <View style={{ paddingVertical: 0 }}>
                    <ApText
                      style={{
                        fontWeight: "500",
                        paddingTop: 6,
                      }}
                    >
                      <ApText
                        style={{ color: "#F37021", fontSize: 15 }}
                        translateKey="CreateNewSpotStrategies"
                      />
                    </ApText>
                    {botPlans.default.filter((bp) => bp?.market === "1")
                      .length > 0 ? (
                      botPlans.default
                        .filter((bp) => bp?.market === "1")
                        .map((item, index) => (
                          <TouchableOpacity
                            key={index}
                            onPress={() => {
                              handleNavigate(item);
                            }}
                          >
                            <View
                              style={{
                                width: "100%",
                                backgroundColor: "#fff5f0ba",
                                marginTop: 10,
                                borderRadius: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                paddingHorizontal: 10,
                                paddingVertical: 15,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "85%",
                                }}
                              >
                                <View style={{ width: 75 }}>
                                  <Image
                                    source={require("../../assets/users/stable-str.png")}
                                    style={{
                                      width: 75,
                                      height: 63,
                                    }}
                                  />
                                </View>
                                <ApText
                                  style={{
                                    color: theme.COLORS.mainDark,
                                    fontWeight: "500",
                                    paddingLeft: 15,
                                  }}
                                >
                                  {item?.label_code}
                                </ApText>
                                {item.plan_type == "1" && (
                                  <View style={styles.botPlanTypeOneLabel}>
                                    <ApText size="sm" color="#fff" translateKey="BotPlanTypeOneLabel"/>
                                  </View>
                                )}
                              </View>
                            </View>
                          </TouchableOpacity>
                        ))
                    ) : (
                      <ApEmptyState
                        heading="Strategy"
                        message="No fixed spot strategy available"
                      />
                    )}
                  </View>

                  <View style={{ paddingVertical: 10 }}>
                    <ApText
                      style={{
                        fontWeight: "500",
                        paddingTop: 6,
                      }}
                    >
                      <ApText
                        style={{ color: "#F37021", fontSize: 15 }}
                        translateKey="MySpotStrategies"
                      />
                    </ApText>
                    {botPlans.my.filter((bp) => bp?.market === "1").length >
                    0 ? (
                      botPlans.my
                        .filter((bp) => bp?.market === "1")
                        .map((item, index) => (
                          <TouchableOpacity
                            key={index}
                            onPress={() => {
                              handleNavigate(item);
                            }}
                          >
                            <View
                              style={{
                                width: "100%",
                                backgroundColor: "#fff5f0ba",
                                marginTop: 10,
                                borderRadius: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                paddingHorizontal: 10,
                                paddingVertical: 15,
                              }}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    width: "10%",
                                    marginHorizontal: 15,
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    source={require("../../assets/users/future-str.png")}
                                    style={{
                                      width: 63,
                                      height: 63,
                                    }}
                                  />
                                </View>

                                <View 
                                  style={{
                                    paddingHorizontal: 15, 
                                    width: "75%", 
                                    flexDirection: "row", 
                                    alignItems: "center"
                                  }}
                                >
                                  <ApText
                                    style={{
                                      fontSize: 15,
                                      color: theme.COLORS.mainDark,
                                      fontWeight: "500",                                    
                                    }}
                                  >
                                    {item?.label_code}
                                  </ApText>

                                  {item.plan_type == "1" && (
                                    <View style={styles.botPlanTypeOneLabel}>
                                      <ApText size="xs" color="#fff" translateKey="BotPlanTypeOneLabel"/>
                                    </View>
                                  )}
                                </View>

                                <TouchableOpacity
                                  style={{
                                    alignItems: "center",
                                  }}
                                  onPress={() => {
                                    setStrategyID(item?.id);
                                    setDeleteModal(true);
                                  }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <svg.RycleBinSvg />
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </TouchableOpacity>
                        ))
                    ) : (
                      <ApEmptyState
                        heading="Strategy"
                        message="No strategy found"
                      />
                    )}
                  </View>
                </>
              )}
            </>
          </View>
        </View>
      </ScrollView>
    )
  }

  return (
    <>
      {renderHeader()}
      {loading ? <ApLoader /> : renderContent()}
      {!loading && (
        <View
          style={{
            width: "100%",
            paddingTop: 10,
            paddingBottom: 70,
            justifyContent: "space-between",
            flexDirection: "row",
            paddingHorizontal: 20,
          }}
        >
          <ApButton
            containerHeight={40}
            style={{ height: "100%" }}
            labelStyle={{ fontSize: 14 }}
            containerWidth="100%"
            round="md"
            labelTranslateKey="ViewAllBots"
            onPress={() => {
              setStatus("1");
              setShowSpotTab(false);
              navigation.navigate("TradingBots", {
                tab: market,
              });
            }}
          />
        </View>
      )}
      <Dialog
        dialogStyle={{ width: "90%" }}
        visible={showBindModal}
        dialogAnimation={
          new SlideAnimation({
            slideFrom: "bottom",
          })
        }
      >
        <TouchableOpacity
          onPress={() => {
            setShowBindModal(false);
          }}
          style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
        >
          <svg.closeSvg />
        </TouchableOpacity>
        <DialogContent>
          <View style={{ alignItems: "center", paddingHorizontal: 6 }}>
            <Image
              source={require("../../assets/api-icon.png")}
              style={{ width: 100, height: 90, marginBottom: 15 }}
            />
            <ApText
              translateKey="BindApiMsg"
              style={{
                color: "#F37021",
                fontWeight: "500",
                marginBottom: 25,
              }}
            />
            <TouchableOpacity
              style={{
                backgroundColor: "#F37021",
                width: "100%",
                height: 38,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
              onPress={() => {
                setShowBindModal(false);
                navigation.navigate("API");
              }}
            >
              <ApText
                translateKey="GoBinding"
                style={{ color: theme.COLORS.white, fontWeight: "500" }}
              />
            </TouchableOpacity>
          </View>
        </DialogContent>
      </Dialog>
      <Dialog
        dialogStyle={{ width: "90%" }}
        visible={deleteModal}
        dialogAnimation={
          new SlideAnimation({
            slideFrom: "bottom",
          })
        }
      >
        <TouchableOpacity
          onPress={() => {
            setDeleteModal(false);
          }}
          style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
        >
          <svg.closeSvg />
        </TouchableOpacity>
        <DialogContent>
          <View style={{ alignItems: "center", paddingHorizontal: 6 }}>
            <ApText
              translateKey="Notice"
              size="lg"
              style={{
                color: "#F37021",
                fontWeight: "500",
                marginBottom: 5,
              }}
            />
            <ApText
              style={{ textAlign: "center", fontSize: 15 }}
              translateKey="ConfirmDeleteMsg"
            />

            <View
              style={{
                width: "100%",
                marginTop: 30,
                flexDirection: "row",
                //paddingVertical: 20,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                style={{
                  marginRight: 10,
                  borderWidth: 1,
                  borderColor: "#F37021",
                  width: "50%",
                  height: 38,
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={() => {
                  setDeleteModal(false);
                }}
              >
                <ApText
                  style={{ color: "#F37021", fontWeight: "500" }}
                  translateKey="Cancel"
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  backgroundColor: "#F37021",
                  width: "50%",
                  height: 38,
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={() => {
                  handleDelete();
                }}
              >
                <ApText
                  style={{ color: theme.COLORS.white, fontWeight: "500" }}
                  translateKey="Confirm"
                />
              </TouchableOpacity>
            </View>
          </View>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Trade;

const styles = StyleSheet.create({
  botPlanTypeOneLabel: {
    marginLeft: 10,
    backgroundColor: 'red',
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 3
  }
})